<template>
  <div class="contact-data fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <nav-bar-page/>

        <v-container>

          <v-row justify="center" class="mt-0">
            <v-col cols="12" class="mt-0 py-0">
              <v-stepper alt-labels
                         elevation="0">
                <v-stepper-header>

                  <v-stepper-step step="" class="active-step today-medium">
                    Formulario
                  </v-stepper-step>

                  <v-divider class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-medium">
                    Validar perfil
                  </v-stepper-step>

                  <v-divider class="active-separator-step"></v-divider>

                  <v-stepper-step step="" class="active-step today-medium">
                    Tipo de tarjeta
                  </v-stepper-step>

                  <v-divider class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-medium">
                    Firma de documentos
                  </v-stepper-step>
                </v-stepper-header>
              </v-stepper>
            </v-col>
            <v-col class="text-center justify-center d-flex d-sm-none">
              <div class="text-h6 today-medium primary--text">
                Tipo de tarjeta
              </div>
            </v-col>

          </v-row>

          <v-row justify="center" class="mt-0">
            <v-col cols="12" class="mt-0 py-5 px-10">

              <v-card color="lighten-5 px-4" elevation="2">

                <v-row justify="center" class="mt-0 pb-5 d-flex align-center ">
                  <v-col cols="12" sm="12" md="5" lg="5" class=" mt-0 py-1 px-1">

                    <v-img max-height="180"
                           class="my-4"
                           contain
                           src="https://app-biocredit.s3.amazonaws.com/web_app/coopcentral/dev/visa_gold-480x326.png"
                    ></v-img>

                    <v-layout justify-center pt-5 class="d-none d-lg-flex d-md-flex d-sm-none">
                      <v-card-actions>
                        <v-btn
                            :loading="loading"
                            :disabled="loading"
                            @click="goSignatureDocuments"
                            color="primary"
                            class="px-12 text-capitalize vardi-button"
                            elevation="0">
                          Solicitar
                        </v-btn>
                      </v-card-actions>
                    </v-layout>

                  </v-col>

                  <v-col cols="12" sm="12" md="7"  lg="7" class="mt-0 pt-4 px-1">

                    <div class="my-0 pt-0 mt-4 primary--text">
                      <h3 class="text-center today-regular line-height-1 text-title">VISA GOLD</h3>

                      <v-row justify="center" class="mt-3">
                        <v-col cols="12" md="10" lg="10">
                          <ul class="black--text">
                            <li class="mt-1 text-justify py-1"><h3 class="today-light line-height-1 text-list">Cuota de manejo exenta por 12 meses.</h3></li>
                            <li class="mt-1 text-justify py-1"><h3 class="today-light line-height-1 text-list">Tasas preferenciales.</h3></li>
                            <li class="mt-1 text-justify py-1"><h3 class="today-light line-height-1 text-list">Cupo desde $ 5.100.000 hasta $12.000.000</h3></li>
                            <li class="mt-1 text-justify py-1"><h3 class="today-light line-height-1 text-list">Compras Nacionales e Internacionales.</h3></li>
                            <li class="mt-1 text-justify py-1"><h3 class="today-light line-height-1 text-list">Acceso a redención de millas LifeMiles.</h3></li>
                            <li class="mt-1 text-justify py-1"><h3 class="today-light line-height-1 text-list">Compra de cartera de otras tarjetas de crédito.</h3></li>
                          </ul>
                        </v-col>
                      </v-row>

                    </div>

                    <v-layout justify-center pt-5 class="d-flex d-md-none d-lg-none d-xl-none">
                      <v-card-actions>
                        <v-btn
                            :loading="loading"
                            :disabled="loading"
                            @click="goSignatureDocuments"
                            color="primary"
                            class="px-12 text-capitalize vardi-button"
                            elevation="0">
                          Solicitar
                        </v-btn>
                      </v-card-actions>
                    </v-layout>

                  </v-col>

                </v-row>

              </v-card>

            </v-col>
          </v-row>

        </v-container>
      </v-col>
    </v-row>


    <v-dialog
        v-model="quotaLimitDialog"
        max-width="400"
        overlay-color="primary"
        persistent
    >
      <v-card class="pa-4 pb-1 text-center">

        <v-img max-height="70"
               class="my-4"
               contain
               src="https://app-biocredit.s3.amazonaws.com/web_app/coopcentral/tarjeta-de-credito.png"
        ></v-img>

        <h2 class="my-3 today-medium justify-center primary--text">LÍMITE DE CUPO</h2>

        <h3 class="text-justify primary--text today-light line-height-1 px-10">
          El límite del cupo de tarjeta de crédito permitido por este canal es de $ 7.000.000.
        </h3>

        <v-row justify="center" class="mt-8">
          <v-col cols="8" md="8" class="mt-0 py-2">
            <v-btn
                color="white"
                class="text-capitalize primary--text today-medium vardi-button"
                block
                style="font-size: 1rem;"
                @click="requestSentAction">
              Solicitar cupo mayor
            </v-btn>
          </v-col>
          <v-col cols="8" md="8" class="mt-0 py-2">
            <v-btn
                :loading="loadingCellphone"
                :disabled="loadingCellphone"
                color="primary"
                block
                class="mt-0 text-capitalize today-medium vardi-button"
                @click="continueProcess"
                elevation="0"
                style="font-size: 1rem;"
            >
              Continuar
            </v-btn>
          </v-col>
        </v-row>

        <v-card-actions class="justify-center">

          <v-btn
              v-if="codeSent"
              :loading="loadingCellphone"
              :disabled="loadingCellphone"
              color="primary"
              class="px-10 mt-0 text-capitalize today-bold vardi-button"
              @click="sendValidateCode"
              elevation="0"
          >
            Enviar
          </v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>


    <v-dialog
        v-model="requestSent"
        max-width="400"
        overlay-color="primary"
        persistent
    >
      <v-card class="pa-4 text-center">

        <v-img max-height="70"
               class="my-4"
               contain
               src="https://app-biocredit.s3.amazonaws.com/web_app/coopcentral/enviar.png"
        ></v-img>

        <h2 class="my-3 today-medium justify-center primary--text">SOLICITUD ENVIADA</h2>

        <h3 class="text-justify primary--text today-light line-height-1 px-10">
          Un asesor comercial se contactará contigo para finalizar el proceso
        </h3>

        <v-row justify="center" class="mt-8">
          <v-col cols="8" md="8" class="mt-0 py-2">
            <v-btn
                :loading="loadingCellphone"
                :disabled="loadingCellphone"
                color="primary"
                block
                class="mt-0 mb-8 text-capitalize today-medium vardi-button"
                @click="goHome"
                elevation="0"
                style="font-size: 1rem;"
            >
              Finalizar
            </v-btn>
          </v-col>

        </v-row>


      </v-card>

    </v-dialog>

    <v-snackbar
        v-model="snackbar"
        :timeout="4000"
        bottom
        right>
      <div v-html="snackbarMessage"></div>
    </v-snackbar>

  </div>
</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";
import Fields from "@/components/Form/Fields";
import {api} from "@/helpers/axios";
import {constants} from "@/helpers/constants";
import {locals} from "@/helpers/locals";

export default {
  name: 'CardType',
  components: {LeftSideImage, NavBarPage, Fields},
  data() {
    return {
      payDialog: false,
      quotaLimitDialog: true,
      loadingCellphone: false,
      loading: false,
      codeSent: false,
      firstFeeValue: 0,
      creditStudy: 15000,
      insurance: 2365,
      ivaValue: 2143,
      othersFeeValue: 0,
      amortizedFee: 0,
      admin: 10000,
      totalValuePolicy: 3500000,
      discountValue: 175716,
      valueFinance: 3324284,
      feeNumber: 2,
      rate: 1.25,
      feeValue: 0,
      expires_in: 60,
      countdown: '',
      dataForm: null,
      snackbar: false,
      snackbarMessage: '',
      rules: {
        required: value => !!value || 'Ingresa la información',
        counterMax: value => value.length <= 10 || 'Cantidad máxima 10 digitos',
        counterMin: value => value.length == 10 || 'Cantidad minima 10 digitos'
      },
      activationKeyFields: [
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' }
      ],
      reSendCode: false,
      requestSent: false
    }
  },
  mounted() {
  },
  methods: {
    continueProcess() {
      this.quotaLimitDialog = false;
    },
    goSignatureDocuments1() {
      // CONSUMIR EL SERVICIO DE VALIDACION DE FORMULARIO

      api.post(constants.endPoints.validateFullForm, {
        user_request_id: this.request.id
      }, true)
          .then(response => {
            console.log('validateFullForm', response);

            this.$router.push('/firma-documentos');

          }).catch(function (error) {
        //
        console.log('errors: ', error);

        try {
          let status = error.response.status;
          if(status == 401){
            locals.clearLocal();
            this.$router.push('/');
          }
          let errors = error.response.data.errors;
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            console.log(key + ' : ' + value);
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;
        } catch (e) {
          console.log(e);
          locals.clearLocal();
          this.$router.push('/');
        }

      }.bind(this))

    },
    sendCellphone: function () {
      this.loadingCellphone = true;

      // Se inicia la cuenta atras
      this.startCountDown();

      setTimeout(function () {
        this.loadingCellphone = false;
        this.codeSent = true;
      }.bind(this), 1000)
    },
    sendValidateCode: function () {
      this.loadingCellphone = true;

      let token = '';

      this.activationKeyFields.forEach((value) => {
        token = token + value.value;
      });

      console.log('token', token);

      if(token.length == 6) {
        api.post(constants.endPoints.validatePhone, {
          cellphone: this.cellphone,
          token: token
        }, true)
            .then((response) => {
              console.log(response);
              let dataResponse = response.data;
              this.loadingCellphone = false;

              if(dataResponse.status) {
                this.createRequest();
              } else {
                this.snackbarMessage = dataResponse.message;
                this.snackbar = true;
              }

            }, (error) => {
              console.log('post', error);
            });
      } else {
        this.snackbarMessage = 'El token debe tener 6 digitos';
        this.snackbar = true;
      }


    },
    handleActivationInput(event) {
      // Grab input's value
      let value = event.target.value;
      // Grab data-index value
      let index = parseInt(event.target.dataset.index);
      // Grab data-length value
      let maxlength = event.target.dataset.length;

      if (this.activationKeyFields[index].value.length > maxlength) {
        event.preventDefault();
        this.activationKeyFields[index].value = value.slice(0, 6);
        try {
          this.$refs[`input-${parseInt(index + 1)}`][0].focus();
        } catch (e) {}
        return;
      }

      // Shift focus to next input field if max length reached
      if (value.length >= maxlength) {
        if (typeof this.activationKeyFields[index + 1] == 'undefined') {
          event.preventDefault();
          return;
        }
        this.$refs[`input-${parseInt(index + 1)}`][0].focus();
        event.preventDefault();
      }
      console.log(event, index, maxlength, this.activationKeyFields);

    },
    formatCurrency: function(value) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,

      });
      return formatter.format(parseInt(value, 10));
    },
    startCountDown: function() {
      this.interval = setInterval(() => {
        if (this.expires_in === 0) {
          //clearInterval(this.interval);
          this.expires_in = 60;
        } else {
          this.expires_in -= 1;
          this.countdown = this.expires_in;
        }
      }, 1000);
    },
    goNext(event){
      console.log(this.$refs.digit2)
      this.$refs.digit2.focus()
    },
    requestSentAction() {
      this.quotaLimitDialog = false;
      this.requestSent = true;
    },
    goSignatureDocuments() {
      this.$router.push('/firma-documentos');
    },
    goHome() {
      locals.clearLocal();
      this.$router.push('/');
    }
  },
  created: function() {
    this.request = locals.getLocal('request');
    api.post(constants.endPoints.form, {
      user_request_id: this.request.id
    }, true)
        .then(response => {
          console.log('form', response);
          this.dataForm = response.data.data.form;
        }).catch(function (error) {
      //
      console.log('errors: ', error);

      try {
        let status = error.response.status;
        if(status == 401){
          locals.clearLocal();
          this.$router.push('/');
        }
        let errors = error.response.data.errors;
        let message = '';
        for (var [key, value] of Object.entries(errors)) {
          console.log(key + ' : ' + value);
          message = message + value + '<br>';
        }
        this.snackbarMessage = message;
        this.snackbar = true;
        this.loading = false;
      } catch (e) {
        console.log(e);
        locals.clearLocal();
        this.$router.push('/');
      }

    }.bind(this))

    /*api.get(constants.endPoints.form, true)
      .then(response => {
        console.log('form', response);
        this.dataForm = response.data.data.form;
      }).catch(function (error) {
        //
        console.log('errors: ', error);

        try {
          let status = error.response.status;
          if(status == 401){
            locals.clearLocal();
            this.$router.push('/');
          }
          let errors = error.response.data.errors;
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            console.log(key + ' : ' + value);
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;
        } catch (e) {
          console.log(e);
          locals.clearLocal();
          this.$router.push('/');
        }

      }.bind(this))*/
  }
}
</script>

<style lang="scss">
.v-stepper__label {
  font-size: 12px;
}

.active-step .v-stepper__label {
  color: #0E4A7D !important;
}

.active-step > span  {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
  background: #0E4A7D !important;
}

.grey-step > span {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin-top: 29px !important;
}

.active-separator-step {
  border-color: #0E4A7D !important;
}

.grey-separator-step {
  border-color: rgba(0, 0, 0, 0.38) !important;
}

.scroll-container {
  height: 400px;
  overflow: scroll;
  overflow-x: hidden;
}

.v-divider {
  border-width: 1px 2px 2px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 29px -82px 0 !important;
}

@media (max-width: 600px) {
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 120px !important;
    padding: 5px !important;
  }
  .v-divider {
    visibility: hidden;
  }
}

li::marker {
  color: #0E4A7D;
}

</style>