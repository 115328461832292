<template>
  <div class="contact-data fill-height">

    <v-row class="fill-height">
      <left-side-image/>
      <v-col cols="12" md="7">

        <nav-bar-page/>

        <v-container>

          <v-row justify="center" class="mt-0">
            <v-col cols="12" class="mt-0 py-0">
              <v-stepper alt-labels
                         elevation="0">
                <v-stepper-header>

                  <v-stepper-step step="" class="active-step today-medium">
                    Formulario
                  </v-stepper-step>

                  <v-divider class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-medium">
                    Validar perfil
                  </v-stepper-step>

                  <v-divider class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-medium">
                    Tipo de tarjeta
                  </v-stepper-step>

                  <v-divider class="grey-separator-step"></v-divider>

                  <v-stepper-step step="" class="grey-step today-medium">
                    Firma de documentos
                  </v-stepper-step>
                </v-stepper-header>
              </v-stepper>
            </v-col>
            <v-col class="text-center justify-center d-flex d-sm-none">
              <div class="text-h6 today-medium primary--text">
                Formulario
              </div>
            </v-col>

          </v-row>

          <v-row justify="center" class="px-4">
            <v-col cols="12" md="10" class="scroll-container">

              <Fields :dataForm="dataForm" ></Fields>

<!--              <v-expansion-panels accordion focusable>

                <v-expansion-panel v-for="(category, index) in dataForm" style="border-radius: 8px;" class="mb-5">
                  <v-expansion-panel-header class="primary&#45;&#45;text today-medium" style="font-size: 1.5em;">
                    {{ category.name }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row justify="center" class="pt-4">
                      <v-col cols="12" md="12">
                        <v-form
                            ref="form"
                            lazy-validation>

                          <div v-for="(dataField, i) in category.fields" >

                            <Fields :dataForm="dataForm" ></Fields>

                          </div>

                        </v-form>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

              </v-expansion-panels>-->


            </v-col>

          </v-row>
          <v-layout justify-center pt-5>
            <v-card-actions>
              <v-btn
                  :loading="loading"
                  :disabled="loading"
                  @click="validateProfile"
                  color="primary"
                  class="px-12 text-capitalize vardi-button"
                  elevation="0">
                Continuar
              </v-btn>
            </v-card-actions>
          </v-layout>


        </v-container>
      </v-col>
    </v-row>


    <v-dialog
        v-model="registerCellphoneDialog"
        max-width="400"
        overlay-color="primary"
        persistent
    >
      <v-card class="pa-4 text-center">

        <v-img max-height="70"
               class="my-4"
               contain
               src="https://app-biocredit.s3.amazonaws.com/web_app/coopcentral/phone+copy.png"
        ></v-img>

        <h2 class="my-3 today-medium justify-center black--text">REGISTRA TU CELULAR</h2>

        <h3 class="text-justify primary--text today-light line-height-1 px-10">
          Te enviaremos vía SMS un código de verificación al número de celular que registres
        </h3>

        <div class="px-10 mt-6" v-if="!codeSent">
          <v-form
              ref="form"
              lazy-validation>

            <v-text-field
                v-model="cellphone"
                :rules="[rules.required, rules.counterMax, rules.counterMin]"
                type="number"
                label="Celular"
                maxlength="10"
                outlined
                dense
                color="primary"
                class="input-field cellphone-input"></v-text-field>

            <v-layout justify-center>
              <v-card-actions>
                <v-btn
                    v-if="!codeSent"
                    :loading="loadingCellphone"
                    :disabled="loadingCellphone"
                    color="primary"
                    class="px-10 mt-0 text-capitalize today-bold vardi-button"
                    @click="sendCellphone"
                    elevation="0"
                >
                  Continuar
                </v-btn>
              </v-card-actions>
            </v-layout>

          </v-form>
        </div>

        <div class="px-10 mt-6" v-if="codeSent">
          <v-form
              ref="form"
              lazy-validation>
            <v-row>
              <v-col v-for="(key, i) in activationKeyFields" cols="2" class="px-2 text-center">
                <v-text-field
                    type="text"
                    :key="i"
                    :data-length="key.length"
                    :data-index="i"
                    :ref="`input-${i}`"
                    v-model="key.value"
                    v-on:keyup="handleActivationInput($event)"
                    class="px-0 input-field code-input"
                    outlined
                    dense
                    color="primary"
                    maxLength="1"></v-text-field>
              </v-col>

            </v-row>

            <p v-if="!reSendCode" class="primary--text today-medium text-center" style="font-size: 0.7em;">Se habilitará un nuevo código en 00:{{ countdown }}</p>

            <p class="primary--text text-center" v-if="reSendCode">
              <a href="#" @click="sendCellphone()" class="text-center today-regular">Reenviar código</a>
            </p>

          </v-form>
        </div>

        <v-card-actions class="justify-center">

          <v-btn
              v-if="codeSent"
              :loading="loadingCellphone"
              :disabled="loadingCellphone"
              color="primary"
              class="px-10 mt-0 text-capitalize today-bold vardi-button"
              @click="sendValidateCode"
              elevation="0"
          >
            Enviar
          </v-btn>
        </v-card-actions>

      </v-card>


    </v-dialog>


    <v-snackbar
        v-model="snackbar"
        :timeout="4000"
        bottom
        right>
      <div v-html="snackbarMessage"></div>
    </v-snackbar>

  </div>
</template>

<script>
import LeftSideImage from "@/components/Layouts/LeftSideImage";
import NavBarPage from "@/components/Layouts/NavBarPage";
import Fields from "@/components/Form/Fields";
import {api} from "@/helpers/axios";
import {constants} from "@/helpers/constants";
import {locals} from "@/helpers/locals";

export default {
  name: 'Form',
  components: {LeftSideImage, NavBarPage, Fields},
  data() {
    return {
      payDialog: false,
      registerCellphoneDialog: false,
      loadingCellphone: false,
      loading: false,
      codeSent: false,
      firstFeeValue: 0,
      creditStudy: 15000,
      insurance: 2365,
      ivaValue: 2143,
      othersFeeValue: 0,
      amortizedFee: 0,
      admin: 10000,
      totalValuePolicy: 3500000,
      discountValue: 175716,
      valueFinance: 3324284,
      feeNumber: 2,
      rate: 1.25,
      feeValue: 0,
      expires_in: 60,
      countdown: '',
      dataForm: null,
      snackbar: false,
      snackbarMessage: '',
      cellphone: '',
      rules: {
        required: value => !!value || 'Ingresa la información',
        counterMax: value => value.length <= 10 || 'Cantidad máxima 10 digitos',
        counterMin: value => value.length == 10 || 'Cantidad minima 10 digitos'
      },
      activationKeyFields: [
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' },
        { length: 1, value: '' }
      ],
      reSendCode: false,
      request: null,
    }
  },
  mounted() {

    api.get(constants.endPoints.phoneIsRegistered, true)
        .then(response => {
          console.log(response.data.status, response.data.data);
          //this.policies = response.data.data;
          //console.log(this.policies);

          if(response.data.status == true) {
            this.registerCellphoneDialog = false;
            this.createRequest();
          } else {
            this.registerCellphoneDialog = true;
          }
        });

  },
  methods: {

    validateProfile() {

      api.post(constants.endPoints.validateFullForm, {
        user_request_id: this.request.id
      }, true)
        .then(response => {
          console.log('validateFullForm', response);

          this.$router.push('/validar-perfil');

        }).catch(function (error) {
        //
        console.log('errors: ', error);

        try {
          let status = error.response.status;
          if(status == 401){
            locals.clearLocal();
            this.$router.push('/');
          }
          let errors = error.response.data.errors;
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            console.log(key + ' : ' + value);
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;
        } catch (e) {
          console.log(e);
          locals.clearLocal();
          this.$router.push('/');
        }

      }.bind(this))

    },
    registerCellphone() {
      this.registerCellphoneDialog = true;
    },
    sendCellphone: function () {
      let validate = this.$refs.form.validate();
      if(validate) {
        this.loadingCellphone = true;

        api.post(constants.endPoints.registerPhone, {
          cellphone: this.cellphone
        }, true)
            .then((response) => {
              console.log(response);
              //this.request.id = response.data.data.id;

              // Se inicia la cuenta atras
              this.startCountDown();
              this.loadingCellphone = false;
              this.codeSent = true;

            }, (error) => {
              console.log('post', error);
            });

      }
    },
    sendValidateCode: function () {
      this.loadingCellphone = true;

      let token = '';

      this.activationKeyFields.forEach((value) => {
        token = token + value.value;
      });

      console.log('token', token);

      if(token.length == 6) {
        api.post(constants.endPoints.validatePhone, {
          cellphone: this.cellphone,
          token: token
        }, true)
            .then((response) => {
              console.log('validatePhone', response);
              let dataResponse = response.data;
              this.loadingCellphone = false;

              if(dataResponse.status) {
                this.createRequest();
              } else {
                this.snackbarMessage = dataResponse.message;
                this.snackbar = true;
              }

            }, (error) => {
              console.log('post', error);
            });
      } else {
        this.loadingCellphone = false;
        this.snackbarMessage = 'El token debe tener 6 digitos';
        this.snackbar = true;
      }


    },
    handleActivationInput(event) {
      // Grab input's value
      let value = event.target.value;
      // Grab data-index value
      let index = parseInt(event.target.dataset.index);
      // Grab data-length value
      let maxlength = event.target.dataset.length;

      if (this.activationKeyFields[index].value.length > maxlength) {
        event.preventDefault();
        this.activationKeyFields[index].value = value.slice(0, 6);
        try {
          this.$refs[`input-${parseInt(index + 1)}`][0].focus();
        } catch (e) {}
        return;
      }

      // Shift focus to next input field if max length reached
      if (value.length >= maxlength) {
        if (typeof this.activationKeyFields[index + 1] == 'undefined') {
          event.preventDefault();
          return;
        }
        this.$refs[`input-${parseInt(index + 1)}`][0].focus();
        event.preventDefault();
      }
      console.log(event, index, maxlength, this.activationKeyFields);

    },
    formatCurrency: function(value) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,

      });
      return formatter.format(parseInt(value, 10));
    },
    startCountDown: function() {
      this.interval = setInterval(() => {
        if (this.expires_in === 0) {
          //clearInterval(this.interval);
          this.expires_in = 60;
          this.reSendCode = true;

        } else {
          this.expires_in -= 1;
          this.countdown = this.expires_in;
          this.reSendCode = false;

        }
      }, 1000);
    },
    goNext(event){
      console.log(this.$refs.digit2)
      this.$refs.digit2.focus()
    },
    createRequest() {
      api.post(constants.endPoints.createRequest, {
      }, true)
          .then((response) => {
            console.log('createRequest', response);
            this.loadingFinance = false;
            this.request.id = response.data.data.id;
            locals.setLocal('request', JSON.stringify(this.request));

            this.getForm();
            this.registerCellphoneDialog = false;
          }, (error) => {
            console.log('Error : createRequest', error);
          });
    },
    getForm() {
      this.request = locals.getLocal('request');
      api.post(constants.endPoints.form, {
        user_request_id: this.request.id
      }, true)
          .then(response => {
            console.log('form', response);
            this.dataForm = response.data.data.form;
          }).catch(function (error) {
        //
        console.log('errors: ', error);

        try {
          let status = error.response.status;
          if(status == 401){
            locals.clearLocal();
            //this.$router.push('/');
          }
          let errors = error.response.data.errors;
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            console.log(key + ' : ' + value);
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;
        } catch (e) {
          console.log(e);
          locals.clearLocal();
          //this.$router.push('/');
        }

      }.bind(this))
    }
  },
  created: function() {

    this.request = {};
    locals.setLocal('request', JSON.stringify(this.request));

    /*api.get(constants.endPoints.form, true)
      .then(response => {
        console.log('form', response);
        this.dataForm = response.data.data.form;
      }).catch(function (error) {
        //
        console.log('errors: ', error);

        try {
          let status = error.response.status;
          if(status == 401){
            locals.clearLocal();
            this.$router.push('/');
          }
          let errors = error.response.data.errors;
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            console.log(key + ' : ' + value);
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;
        } catch (e) {
          console.log(e);
          locals.clearLocal();
          this.$router.push('/');
        }

      }.bind(this))*/
  }
}
</script>

<style lang="scss">
.v-stepper__label {
  font-size: 12px;
}

.active-step .v-stepper__label {
  color: #0E4A7D !important;
}

.active-step > span  {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
  background: #0E4A7D !important;
}

.grey-step > span {
  min-width: 12px !important;
  height: 12px !important;
  width: 12px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin-top: 29px !important;
}

.active-separator-step {
  border-color: #0E4A7D !important;
}

.grey-separator-step {
  border-color: rgba(0, 0, 0, 0.38) !important;
}

.scroll-container {
  height: 400px;
  overflow: scroll;
  overflow-x: hidden;
}

.v-divider {
  border-width: 1px 2px 2px !important;
}

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 29px -82px 0 !important;
}

.cellphone-input {
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] { -moz-appearance:textfield; }
}

@media (max-width: 600px) {
  .v-stepper--alt-labels .v-stepper__step {
    flex-basis: 120px !important;
    padding: 5px !important;
  }
  .v-divider {
    visibility: hidden;
  }
}

</style>